.co-container {
    padding: 10px;
    margin-top: 10px;
}

.co-item {
    display: flex;
    background-color: #F5FBF8;
    border-radius: 10px;
    border: 0.2px solid #D5D5D7;
    /* padding: 10px; */
    
    margin-top: 10px;
}

.co-field {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    /* justify-content: center; */
}

.co-field-action {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 20px;
}

.co-field-action>.btn {
    width: 60px;
    margin-top: 5px;
}

.co-product-image {
    height: 100px;
    width: 100px;
    background-size: cover;
    border-radius: 10px;
}

.co-field-label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-top: 8px;
}

.co-field-value {
    font-size: 13px;
}