.is-danger {
  border: 1px solid #C80000;
}

.form-group {
  position: relative;
}

.react-datetime-picker {
  width: 100%;
}

.react-datetime-picker__wrapper {
  padding: .375rem .75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-datetime-picker__wrapper:hover {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.react-datetime-picker__button {
  outline: none !important;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.page-item.active .page-link {
  background-color: #20a8d8 !important;
  border-color: #20a8d8 !important;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-text {
  color: #20a8d8 !important;
}

.btn-info {
  color: white !important;
}

.hide {
  display: none;
}

.all-user-ucnm {
  height: 300px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}


.parent-dropdown {
  cursor: pointer;
  display: flex !important;
  
  justify-content: space-between;
  position: relative;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parent-dropdown>span {
}

.parent-dropdown-active {
  position: absolute;
  background-color: #EDEDEE;
  border: 1px solid #e4e7ea;
  width: 100%;
  z-index: 999;
}

.parent-dropdown-active-search {
  padding: 10px;
}

.parent-dropdown-active-container {
  max-height: 310px;
  overflow-y: auto;
}

.parent-dropdown-active-row {
  padding: 5px 15px;
  transition: 0.15s;
}

.parent-dropdown-active-row.active, .parent-dropdown-active-row:hover {
  background-color: #2196F3;
  color: white;
}