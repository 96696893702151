.form-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-topbar-title {}

.form-topbar-options {}

.switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 26px;
    margin-left: 15px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

th {
    cursor: pointer;
}

.rl-container {}

.rl-container .fa {
    margin: 0px 5px;
    color: #20A8D8;
    cursor: pointer;
}

.rl-value {}

.pagination {
    padding: 10px;
    display: flex;
    justify-content: center;

}

.pagination-link:first-child {
    border-radius: 5px 0px 0px 5px;
}

.pagination-link:last-child {
    border-radius: 0px 5px 5px 0px;
}

.pagination-link:hover, .pagination-link.active {
    background-color: #20A8D8;
    color: #fff;
    border: 0.5px solid #20A8D8;
}

.pagination-link {
    border: 0.5px solid #B4E2F2;
    padding: 5px 10px;
    text-decoration: none;
    transition: 0.5s;
}

.pagination-link:hover, .pagination-link:visited, .pagination-link:link, .pagination-link:active {
    text-decoration: none;
}

.pagination-link .active {}