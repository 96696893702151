@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    #addProductModal .modal-dialog {
        min-width: auto !important;
    }
    .apm-s-store-container {
        width: 100%;
    }
    .apm-s-store-products-conatainer {
        padding: 3px !important;
    }
    .apm-s-store-sub-categories-container {
        width: auto !important;
    }
    .bs-carousel-content {
        height: 310px !important;
    }
    .carousel-data>h1 {
        font-size: 16px;
    }
    .shop-btn {
        width: 80px;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
    }
    .apm-s-store-top-container {
        padding: 0px 10px !important;
        top: 180px !important;
    }
    .apm-s-store-logo {
        height: 80px !important;
        width: 80px !important;
        background-size: 60px !important;
    }
    .apm-s-store-top-content {
        padding: 0px 10px !important;
        width: 75%;
    }
    .apm-s-store-top-content>h3 {
        font-size: 16px;
    }
    .apm-s-store-top-content>span {
        font-size: 12px;
    }
    .rating-container>i {
        margin: 2px !important;
        font-size: 10px;
    }
    .apm-s-store-categories-container {
        display: none !important;
    }
    .apm-s-store-mobile-categories-container {
        display: flex !important;
        width: 100% !important;
        height: 100% !important;
        flex-wrap: wrap;
    }
    .apm-s-store-all-products {
        width: 100%;
        height: 100px !important;
        align-items: center !important;
    }
    .apm-s-store-mobile-categories-container .apm-s-store-category-item {
        width: 50% !important;
        height: 150px;
    }
    .apm-s-store-mobile-categories-inner-container {
        display: none;
    }
    .apm-s-store-inner-container {
        width: 100% !important;
    }
    .apm-s-store-product-item {
        width: 42% !important;
        height: 250px !important;
    }
    .apm-s-store-products-conatainer {
        height: 70vh !important;
    }
    .apm-s-store-search-suggest-container {
        width: 92% !important;
    }
    .apm-s-store-mobile-menu-icon {
        display: flex !important;
        margin-right: 20px;
    }
    .apm-s-products-not-found-container {
        height: 100% !important;
    }
}

#addProductModal .modal-dialog {
    min-width: 925px
}

.apm-s-store-mobile-menu-icon {
    display: none;
}

.bs-carousel-content {
    height: 350px;
    background-size: cover !important;
    background-position-x: center !important;
    background-position-y: center !important;
    justify-content: flex-end;
    padding-left: 0%;
    padding-right: 15%;
}

.bs-carousel-content .carousel-data {
    text-align: right;
}

.p-item-carousel-content {
    height: 330px !important;
    background-size: contain !important;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
}

.apm-s-store-main-container {
    position: relative;
}

.apm-s-store-top-container {
    padding: 0px 100px;
    /* margin-top: -100px; */
    position: absolute;
    top: 200px;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.apm-s-store-logo {
    height: 120px;
    width: 120px;
    border-radius: 100px;
    background-size: 100px;
    background-repeat: no-repeat;
    background-color: white;
    background-position-x: center;
    background-position-y: center;
    border: 1px solid #00923F;
}

.apm-s-store-top-content {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.45);
}

.apm-s-store-change-link {
    color: #AADABF;
    font-weight: 700;
    cursor: pointer;
}

.apm-s-store-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.apm-s-store-categories-container {
    /* flex: 0.4; */
    width: 16vh;
    /* height: 100vh; */
    overflow-y: scroll;
    background-color: #E2F2E9;
}

.apm-s-store-categories-container::-webkit-scrollbar, .apm-s-store-products-conatainer::-webkit-scrollbar, .apm-s-store-product-name::-webkit-scrollbar, .apm-s-store-search-suggest-container::-webkit-scrollbar, .pam-container::-webkit-scrollbar {
    margin-top: 10px;
    /* height: 4px; */
    width: 5px;
}

.apm-s-store-categories-container::-webkit-scrollbar-track, .apm-s-store-products-conatainer::-webkit-scrollbar-track, .apm-s-store-product-name::-webkit-scrollbar-track, .apm-s-store-search-suggest-container::-webkit-scrollbar-track, .pam-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.apm-s-store-categories-container::-webkit-scrollbar-thumb, .apm-s-store-products-conatainer::-webkit-scrollbar-thumb, .apm-s-store-product-name::-webkit-scrollbar-thumb, .apm-s-store-search-suggest-container::-webkit-scrollbar-thumb, .pam-container::-webkit-scrollbar-thumb {
    background-color: #00923F;
    /* outline: 1px solid slategrey; */
}

.apm-s-store-products-conatainer {
    position: relative;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    /* height: 83.5vh; */
    overflow-y: scroll;
}

.apm-s-store-inner-container {
    /* flex: 0.9; */
    width: 70vh;
    background-color: #DEE2E6;
}

.apm-s-store-search-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
}

.apm-s-store-search-field-group {
    display: flex;
    width: 500px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
}

.apm-s-store-search-field {
    flex: 1;
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    outline: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.apm-s-store-search-button {
    flex: 0.1;
    background-color: #00923F;
    color: white;
    cursor: pointer;
}

.apm-s-store-search-suggest-container {
    position: absolute;
    width: 500px;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
    height: 300px;
    overflow-y: scroll;
    top: calc(2.8em + .75rem + 2px);
}

.apm-s-store-search-suggest-item {
    padding: 12px;
    font-size: 12.5px;
    cursor: pointer;
    transition: 0.5s;
}

.apm-s-store-search-suggest-item:hover {
    background-color: #EDEDED;
}

.apm-s-store-sc-top-container {
    height: 20px;
}

.apm-s-store-sub-categories-container {
    background-color: white;
    cursor: grab;
    display: flex;
    overflow-x: scroll;
    /* width: 100%; */
    width: 70vh;
    /* height: 20px; */
    /* margin: 10px; */
}

.apm-s-store-sub-categories-container::-webkit-scrollbar {
    margin-top: 10px;
    height: 4px;
}

.apm-s-store-sub-categories-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.apm-s-store-sub-categories-container::-webkit-scrollbar-thumb {
    background-color: #00923F;
    /* outline: 1px solid slategrey; */
}

.apm-s-store-sc-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    margin: 0.6rem;
    white-space: nowrap;
    padding: 0 7px 0;
    color: #262626 !important;
    font-size: 12px;
    font-weight: 600;
    background: #f6f6f6;
    border-radius: 20px;
    -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 30%), 0 0 40px rgb(0 0 0 / 10%) inset;
    box-shadow: 0 1px 4px rgb(0 0 0 / 30%), 0 0 40px rgb(0 0 0 / 10%) inset;
    -webkit-transition: all 0.125s linear;
    transition: all 0.125s linear;
    cursor: pointer;
}

.active.apm-s-store-sc-item {
    background: #00923F;
    color: #fff !important;
}

.apm-s-store-sc-item:hover {
    background: #00923F;
    color: #fff !important;
}

.apm-s-store-product-item {
    width: 30%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;
    min-width: 115px;
    height: 250px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    margin: 10px;
    -webkit-box-shadow: 0px 0px 5px #666;
    box-shadow: 0px 0px 5px #666;
}

.apm-s-store-product-item:hover {
    -webkit-box-shadow: 0px 0px 12px #666 !important;
    box-shadow: 0px 0px 12px #666 !important;
}

.apm-s-store-product-image-container {
    flex: 2;
    width: 100%;
    /* padding: 20px; */
    background-color: white;
}

.apm-s-store-product-image {
    background-size: 140px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.apm-s-store-image-top-content {
    flex: 1;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
}

.apm-s-product-remove-cart-button {
    width: 23px;
    height: 23px;
    border-radius: 8px;
    text-align: center;
    /* line-height: 30px; */
    background-color: red;
    /* line-height: 24px; */
}

.apm-s-product-remove-cart-button>i {
    color: #fff;
    font-weight: bold;
    font-size: 8px;
}

.apm-s-product-cart-qty {
    width: 26px;
    height: 23px;
    text-align: center;
    vertical-align: middle;
    background-color: #22cc22;
    font-size: 11px;
    font-weight: 700;
    border-radius: 8px;
    line-height: 23px;
    color: #fff;
}

.apm-s-store-image-bottom-content {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    /* background-color: yellow; */
}

.apm-s-store-info-icon {
    margin-right: 8px;
    margin-bottom: 5px;
    font-size: 20px;
    color: #868e96;
    opacity: 0.75;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    padding: 0;
    border: none;
    background: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.apm-s-store-info-icon:hover {
    opacity: 1;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

.apm-s-store-info-icon:focus {
    outline: none;
}

.apm-s-store-product-content {
    flex: 1;
    background-color: #EDEDED;
    padding: 10px;
}

.apm-s-store-product-name {
    font-size: 13px;
    max-height: 55px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    line-height: 1.4;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    margin: 0 auto;
    color: black;
    height: 35px;
    overflow-y: auto;
    padding-right: 5px;
}

.apm-s-store-product-packaging {
    color: #777;
    font-size: 11px;
    direction: ltr;
}

.apm-s-store-product-price {
    font-weight: bold;
    color: #222;
    font-size: 11.5px;
    line-height: 25px;
    padding-right: 10px;
    float: right;
}

.apm-s-store-category-item {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    height: 180px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
}

.apm-s-store-category-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: linear-gradient(0deg, rgba(0.41, 0.41, 0.41, 0.3), rgba(0.41, 0.41, 0.41, 0.3));
    color: white;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    border-right: 1px solid #666;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.apm-s-store-category-item:hover .apm-s-store-category-content {
    background-color: #00923F;
}

.active.apm-s-store-category-item .apm-s-store-category-content {
    background-color: #00923F;
}

.apm-s-store-all-products {
    color: white;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: flex-end;
    height: 50px;
    background-color: #444;
    cursor: pointer;
}

.apm-s-products-not-found-container {
    flex: 1;
    flex-direction: column;
    height: 100vh;
}

.apm-s-notfound-icon {
    font-size: 75px;
    color: #00923F;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.apm-s-product-not-found-content {
    padding: 40px;
}

.pm-title-container {
    margin-bottom: 30px;
}

.pm-description {
    padding: 10px 30px;
}

.pm-carousel-icon {
    font-size: 30px;
    color: #444444;
}

.pam-container {
    height: 400px;
    overflow-y: scroll;
}

.pam-item {
    margin-bottom: 30px;
}

.pam-title-container {
    display: flex;
    justify-content: space-between;
    background-color: #ECEBF0;
    padding: 10px;
    border-radius: 10px;
}

.pam-title {
    font-size: 16px;
    font-weight: 700;
}

.pam-clear-button {
    color: #00923F;
    font-size: 14px!important;
    font-weight: 900;
    cursor: pointer;
}

.pam-clear-button>span {
    margin-left: 4px;
}

.pam-list-container {
    display: flex;
    flex-direction: column;
}

.pam-list-item {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #ECEBF0;
    cursor: pointer;
    align-items: center;
}

.pam-list-radio-button>i {
    font-size: 16px;
}

.pam-list-radio-button>.checked {
    color: #00923F;
}

.pam-list-name {
    margin: 0px 5px;
    font-size: 16px;
}

.pam-list-price {
    font-size: 13px;
    color: #A6A6A6;
}

.pam-modal-footer {
    flex-direction: column;
}

.pam-qty-container {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.pam-qty-container>span {
    margin: 0px 15px;
}

.pam-qty-container>i {
    color: #00923F;
    cursor: pointer;
}

.apm-s-product-outofstock-container {
    display: flex;
    align-items: center;
    padding: 10px;
    /* width: 100%; */
}

.apm-s-product-outofstock {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 0 0 / 60%);
    color: white;
    height: 25px;
    border-radius: 10px;
}

.pm-detail-image {
    height: 300px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 300px;
}