.cg-container {
    display: flex;
    flex-wrap: wrap;
}

.cg-item {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 20px;
}

.cg-field {
    /* flex:1; */
    width: 30%;
    padding: 5px
}

.cg-button-field {
    width: 5% !important;
    /* flex: 0.2 !important; */
}

.cg-button-field>button {
    margin-top: 28px;
    color: white;
}

.cg-button-field>button:hover {
    color: white;
}