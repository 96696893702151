

.home-balance-item {
    background-color: #e2faed;
    /* border-radius: 20px; */
    height: 100px;
    box-shadow: 2px 2px 3px #888888;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.home-balance-item-content {
    text-align: right
}

.hbi-text {
    display: block;
    color: #1A2C79;
}

.hbi-icon-container {
    height: 3.5em;
    width:  3.5em;
    display: flex;
    align-items: center;
    justify-content: center;    
    /* background-color: red; */
    border-radius: 100pt;
}

.hbi-icon{
    color: white;
    font-size: 1.7em;
    margin-top: -1px;
}