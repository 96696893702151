.d-search-main-container {
    display: flex;
    flex: 1;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
    /* width: 100%; */
}

.d-search-container {
    display: flex;
    flex: 0.4;
    justify-content: space-between;
    align-items: center;
    background-color: #F8FCFA;    
    border: 0.2px solid #eeeef0;
    border-radius: 30px;
    padding-left: 10px;
    cursor: pointer;
}

.d-search-button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: .375rem .75rem;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    background-color: transparent;
    border: 1px solid transparent;
}

.d-search-button:focus {
    outline: none;
    color: #5a7391;
}

.d-search-field {
    outline: none;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    background-color: transparent;
    border-radius: .25rem;
    border: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}