@media only screen and (min-device-width: 350px) and (max-width: 1024px) {
    .o-product-item {
        margin: 5px !important
    }
    .o-product-image {
        height: 50px !important;
        width: 50px !important;
    }
    .o-product-title, .o-product-description {
        font-size: 11px !important;
        max-height: 70px !important;
        overflow-y: scroll;
    }

    .o-product-field {
        padding: 3px !important;
    }
    
}
.o-place-datetime {
    font-size: 11px !important;
    line-height: 14px;
    font-weight: 500 !important;
}

.o-products-container {
    margin-top: 10px;
    background-color: #F5FBF8;
    border-radius: 5px;
    box-shadow: 0px 0px 2px #888;
}

.o-product-item {
    display: flex;
    justify-content: space-between;
    margin: 15px;
    border-bottom: 2px dotted #CCCCCC;
}

.o-product-field {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.o-product-image {
    height: 70px;
    width: 70px;
    background-size: cover;
    background-repeat: no-repeat;
}

.o-product-title {
    font-size: 13px;
    font-weight: 700;
}

.o-product-edit {
    display: flex;
    flex-direction: row;
}

.o-product-qty {
    margin: 0px 20px;
}

.o-product-qty .fa {
    cursor: pointer;
    margin: 0px 5px;
}

.o-product-addon-container {
    padding: 3px 10px;
}

.o-product-addon-title {
    font-size: 12px;
    background-color: #00923F;
    padding: 2px 10px;
    color: white;
    border-radius: 5px;
    font-weight: 600;
}

.o-product-child-addon-container {
    padding: 0px 10px;
    display: flex;
}

.o-product-child-addon {
    color: #00923F;
    font-size: 12px;
    font-weight: 600;
}

.o-product-description {
    font-size: 12px;
    line-height: 18px;
    color: #666666;
}

.o-total-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.o-total-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.o-total-item {
    display: flex;
    padding: 5px;
}

.o-total-label{
    flex: 2.2;
    font-weight: 600;
}
.o-total {
    flex: 1;
    font-weight: 700;
}

.o-status-container {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #CCCCCC;
}

.o-status-heading {
    font-weight: 700;
}

.o-status-option-container {
    display: flex;
}

.o-status-option {
    /* flex: 1; */
    padding: 3px 10px;
    border: 1px solid #999999;
    font-size: 12px;
    border-radius: 40px;
    margin: 0px 5px;
    cursor: pointer;
    background-color: white;
    transition: .15s;
}

.o-status-option:hover {
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 0.2rem rgb(0 146 63 / 25%);
    background-color: #00923F;
    color: white;
}

.o-cl-container {
    margin-top: 13px;
    background-color: #F5FBF8;
    border-radius: 5px;
    box-shadow: 0px 0px 2px #888;
}

.o-cl-content {
    padding: 5px
}

.o-cl-item {
    padding: 10px;
}

.o-cl-head {
    display: flex;
    justify-content: space-between;
}

.o-cl-heading {
    font-size: 13px;
    font-weight: 700;
}

.o-cl-option {
    font-size: 12px;
    color: #00923F;
    cursor: pointer;
}

.o-cl-value {
    font-size: 12px;
}

.o-add-product-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
}

.o-notes{
    padding: 10px;
}


.dashboard-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-title-area {}

.dashboard-title-area>span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
}

.dashboard-button-area {}

.dashboard-content {
    margin-top: 30px;
}


.co-status-container {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #CCCCCC;
}

.co-status-heading {
    font-weight: 700;
}