.search-input {
    width: 100%;
    /* height: 65px; */
    height: calc(2.25rem + 2px);
    border-radius: 100px;
    border: none;
    /* padding-top: 1rem; */
    /* padding-left: 2.25rem; */
}

.search-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #00923F;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 146 63 / 25%);
}

@media only screen and (min-device-width: 480px) and (max-width: 769px){
    .search-input {
        padding-top: 0px;
    }
}

/* .search-input:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
} */

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 99999;
    /* margin-top: 2rem; */
    border: 0.5px solid #00923F !important;
    width: 100%;
    /* margin-left: 10px; */
}

.suggestion-cont {
    padding: 10px;
}
.found-results{
    
}